import configureStore from 'general/configureStore';

import { createGeneralComponents } from 'shared/general';

function run() {
	//check if there is a master app if not run  general components standalone;
	if (!window.__MASTER_REACT_APP__) {
		const initialState = {};

		const store = configureStore(initialState);
		createGeneralComponents(store);
	}
}

const runApp = { run: run };
export default runApp;
